import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
// import CryptoPaymentsView from '../../pages/dashboard/cryptoPayments';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const CreateSmartDocPage = lazy(() => import('src/pages/dashboard/createsmartdoc'));
const TransferSmartDocPage = lazy(() => import('src/pages/dashboard/transfersmartdoc'));
const ReceiveSmartDocPage = lazy(() => import('src/pages/dashboard/receivesmartdoc'));
const VerifySmartDocPage = lazy(() => import('src/pages/dashboard/verifysmartdoc'));
const ImportSmartDocPage = lazy(() => import('src/pages/dashboard/importsmartdoc'));
const AttachDocumentPage = lazy(() => import('src/pages/dashboard/attach-document'));

const ChannelsPage = lazy(() => import('src/pages/dashboard/channels'));
const GetSigningKeyPage = lazy(() => import('src/pages/dashboard/signingkey'));

const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));

// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// App Store
const AppsPage = lazy(() => import('src/pages/dashboard/apps'));
const MyAppsPage = lazy(() => import('src/pages/dashboard/myapps'));
const AppDetailsPage = lazy(() => import('src/pages/dashboard/apps/details'));
const MyAppDetailsPage = lazy(() => import('src/pages/dashboard/myapps/details'));
const ContactsPage = lazy(() => import('src/pages/dashboard/contacts'));
const ExplorerPage = lazy(() => import('src/pages/dashboard/explorer'));
const DocuSign = lazy(() => import('src/pages/dashboard/apps/DocuSign'));
const AccountAddOrganization = lazy(() => import('src/sections/organization/organization-form'));
const DemoPage = lazy(() => import('src/pages/dashboard/demo/list'));
const DemoCreatePage = lazy(() => import('src/pages/dashboard/demo/new'));
const DemoEditPage = lazy(() => import('src/pages/dashboard/demo/edit'));

const AdminCreateFormsPage = lazy(() => import('src/pages/admin/forms/createForms'));
const AdminCreateJSONFormsPage = lazy(() => import('src/pages/admin/forms/createJSONForms'));
const AdminGeneratorPage = lazy(() => import('src/pages/admin/forms/generator'));

const MemberPage = lazy(() => import('src/pages/dashboard/member'));
const OrganizationViewPage = lazy(() => import('src/pages/dashboard/organization/view'));
const OrganizationListPage = lazy(() => import('src/pages/dashboard/organization/list'));
const OrganizationNewPage = lazy(() => import('src/pages/dashboard/organization/new'));
const OrganizationEditPage = lazy(() => import('src/pages/dashboard/organization/edit'));
const OrganizationVerifyPage = lazy(() => import('src/pages/dashboard/organization/verify'));
const OrganizationTemplatesListPage = lazy(() =>
  import('src/pages/dashboard/organization/templates-list')
);
const OrganizationTemplatesEditPage = lazy(() =>
  import('src/pages/dashboard/organization/templates-edit')
);
const UserVerifyPage = lazy(() => import('src/pages/dashboard/user/verify'));

const SmartDocRegistrationPage = lazy(() =>
  import('src/pages/dashboard/smart-doc-registration-page')
);
const SmartDocActionPage = lazy(() => import('src/pages/dashboard/smart-doc-action-page'));
const ConfirmationPage = lazy(() => import('../../sections/confirmationPage'));
const ResultPageView = lazy(() => import('../../sections/resultPageView'));
const TokenizedOwnerListView = lazy(() => import('../../sections/tokenized-owner'));
const ImportDocDetailsView = lazy(() => import('../../sections/importDocDetails'));
const OrgAdmins = lazy(() => import('../../sections/orgAdmins'));
const ForgotPassword = lazy(() => import('src/pages/auth/firebase/forgot-password'));
const ComingSoonPage = lazy(() => import('src/pages/coming-soon'));
const ClioAppPage = lazy(() => import('src/pages/dashboard/myapps/clio-app'));
const ESignaturePage = lazy(() => import('src/pages/dashboard/e-signature'));
const ArchivedDocumentsPage = lazy(() => import('src/pages/dashboard/archived-documents'));
const JobEdit = lazy(() => import('src/sections/myapps/view/job-edit'));
const PrismaticPage = lazy(() => import('src/pages/prismatic-page'));
const CyclrPage = lazy(() => import('src/pages/cyclr-embed'));
const CyclrEmbed = lazy(() => import('src/pages/cyclr-user-marketplace-embed'));
const MarketplaceEmbed = lazy(() => import('src/pages/cyclr-marketplace'));

const IntegrationAppPage = lazy(() => import('src/pages/integration-app-embed'));
const VimeoPlayer = lazy(() => import('src/pages/vimeo/vimeo-view'));
const TransfertokenPage = lazy(() => import('src/pages/tokenizedTransferView'));
const WebhookTest = lazy(() => import('src/pages/webhooksTest'));
const CryptoPaymentsView = lazy(() => import('src/pages/dashboard/cryptoPayments'));
const StripeView = lazy(() => import('src/sections/stripe'));
const Complete = lazy(() => import('../../sections/stripe/completePage'));
const Demo = lazy(() => import('../../sections/stripe/demo'));

// import ConfirmationView from '../../sections/confirmationPage';
// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'createsmartdoc', element: <CreateSmartDocPage /> },
      { path: 'documentdetails', element: <ImportDocDetailsView /> },
      { path: 'transfersmartdoc', element: <TransferSmartDocPage /> },
      { path: 'receivesmartdoc', element: <ReceiveSmartDocPage /> },
      { path: 'verifysmartdoc', element: <VerifySmartDocPage /> },
      { path: 'importsmartdoc', element: <ImportSmartDocPage /> },
      { path: 'signingkey', element: <GetSigningKeyPage /> },
      { path: 'channels', element: <ChannelsPage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      { path: 'apps', element: <AppsPage /> },
      { path: 'attach_document', element: <AttachDocumentPage /> },
      {
        path: 'myapps',
        children: [
          { element: <MyAppsPage />, index: true },
          { path: ':id', element: <MyAppDetailsPage /> },
          { path: 'detail/:id', element: <JobEdit /> },
          { path: 'config/:id', element: <JobEdit /> },
        ],
      },
      {
        path: 'apps',
        children: [
          { element: <AppsPage />, index: true },
          // { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <AppDetailsPage /> },
          { path: 'docusign/:id', element: <DocuSign /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      { path: 'contacts', element: <ContactsPage /> },
      { path: 'explorer', element: <ExplorerPage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
          { path: 'verify', element: <UserVerifyPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },

      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },

      // { path: 'demo', element: <DemoPage /> },

      {
        path: 'org',
        children: [
          { element: <OrganizationViewPage />, index: true },
          { path: 'list', element: <OrganizationListPage /> },
          { path: 'new', element: <OrganizationNewPage /> },
          { path: ':id', element: <OrganizationEditPage /> },
          { path: 'verify', element: <OrganizationVerifyPage /> },
          { path: 'templates', element: <OrganizationTemplatesListPage /> },
          { path: 'templates/:id', element: <OrganizationTemplatesEditPage /> },
        ],
      },
      {
        path: 'members',
        children: [
          { element: <MemberPage />, index: true },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id', element: <UserEditPage /> },
        ],
      },
      {
        path: 'demo',
        children: [
          { element: <DemoPage />, index: true },
          { path: 'new', element: <DemoCreatePage /> },
          { path: ':id/edit', element: <DemoEditPage /> },
        ],
      },
      {
        path: 'forms',
        children: [
          {
            path: 'create',
            element: <AdminCreateFormsPage />,
          },
          {
            path: 'json-create',
            element: <AdminCreateJSONFormsPage />,
          },
          {
            path: 'generate',
            element: <AdminGeneratorPage />,
          },
        ],
      },

      { path: 'registration', element: <SmartDocRegistrationPage /> },
      { path: 'file-action', element: <SmartDocActionPage /> },
      { path: 'confirmation', element: <ConfirmationPage /> },
      { path: 'resultview', element: <ResultPageView /> },
      { path: 'orgadmins', element: <OrgAdmins /> },
      { path: 'forgotpassword', element: <ForgotPassword /> },
      { path: 'tokenizedowner', element: <TokenizedOwnerListView /> },
      {
        path: 'intel',
        children: [
          { element: <MyAppDetailsPage intel />, index: true },
          { path: 'config', element: <JobEdit intel /> },
        ],
      },
      {
        path: 'clio',
        children: [
          { element: <ClioAppPage />, index: true },
          { path: 'config', element: <JobEdit clio /> },
        ],
      },
      // { path: 'app/intel', element: <MyAppDetailsPage intel /> },
      // { path: 'app/intel/config', element: <JobEdit intel /> },
      { path: 'e-signature', element: <ESignaturePage /> },
      { path: 'archived-documents', element: <ArchivedDocumentsPage /> },
      { path: 'zapier', element: <ComingSoonPage /> },
      { path: 'prismatic', element: <PrismaticPage /> },
      { path: 'cyclr', element: <CyclrPage /> },
      { path: 'user-marketplace', element: <CyclrEmbed /> },
      { path: 'marketplace', element: <MarketplaceEmbed /> },
      { path: 'integration-app', element: <IntegrationAppPage /> },
      { path: 'vimeo-player', element: <VimeoPlayer /> },
      { path: 'transfer-token', element: <TransfertokenPage /> },
      { path: 'webhookstest', element: <WebhookTest /> },
      { path: 'crypto-payments', element: <CryptoPaymentsView /> },
      { path: 'payment', element: <StripeView /> },

      { path: 'complete', element: <Demo /> },
    ],
  },
];
