import axios from 'axios';
// config
import { SWITCHBOARD_AUTH_HOST_API } from 'src/config-globals';

// ----------------------------------------------------------------------

const auth_axios = axios.create({ baseURL: SWITCHBOARD_AUTH_HOST_API });

// export const getTokenData = () => {
//   const tokenData = localStorage.getItem('refreshToken');
//   return tokenData;
// };

// const BASE_URL = process.env.REACT_APP_SWITCHBOARD_AUTH_HOST_API;

// const reCallFailedRequest = axios.create({
//   baseURL: BASE_URL,
// });

// const refreshTokenApi = async () => {
//   try {
//     const Refresh_Token = await getTokenData();
//     const result = await auth_axios.post(authendpoints.authnew.refresh_token, {
//       refresh: Refresh_Token,
//     });

//     localStorage.setItem('authToken', result.data.accessToken);

//     // Return the new token or a success indicator
//     return result.data.accessToken;
//   } catch (error) {
//     // Return the error for handling
//     return error;
//   }
// };

// auth_axios.interceptors.response.use(
//   (res) => res,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

auth_axios.interceptors.response.use(
  (res) => res,
  (error) => {
    if (error?.response?.status === 401) {
      try {
        window.location.reload();
        localStorage.removeItem('authToken');
      } catch (error1) {
        console.log('🚀 ~ error1:', error1);
        return Promise.reject(error1);
      }
    }

    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);
export default auth_axios;

// let isRefreshing = false;
// let subscribers = [];

// // Function to add subscribers to the queue
// const onRrefreshed = (cb) => {
//   subscribers.push(cb);
// };

// // Function to notify all subscribers once the token has been refreshed
// const notifySubscribers = (token) => {
//   subscribers.forEach((cb) => cb(token));
//   subscribers = [];
// };

// auth_axios.interceptors.response.use(
//   (response) => response, // Simplified arrow function

//   async (error) => {
//     console.log('🚀 ~ error:', error);
//     const originalRequest = error.config;

//     if (error.response && error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;

//       if (isRefreshing) {
//         // If refresh in progress, add to queue
//         return new Promise((resolve, reject) => {
//           onRrefreshed((token) => {
//             originalRequest.headers.Authorization = `Bearer ${token}`;
//             resolve(auth_axios(originalRequest));
//           });
//         });
//       }

//       isRefreshing = true;

//       try {
//         const newAccessToken = await refreshTokenApi();
//         notifySubscribers(newAccessToken);

//         // Retry the original request
//         originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
//         return auth_axios(originalRequest);
//       } catch (catchError) {
//         console.error('Error during retry after token refresh:', catchError);
//         return Promise.reject(catchError);
//       } finally {
//         isRefreshing = false;
//       }
//     }

//     return Promise.reject((error.response && error.response.data) || 'Something went wrong');
//   }
// );

// export default auth_axios;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await auth_axios.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const authendpoints = {
  authnew: {
    signup: '/auth/register',
    login: '/auth/login',
    userinfo: '/auth/me',
    content: '/switchboard/get_documents',
    files: '/switchboard/get_documents',
    bctrans: 'transactions/findAll',
    channel: '/switchboard/get_channels',
    channel_message: '/switchboard/get_channel_messages',
    get_documents: '/document/all/0',
    document: '/document',
    document_no_register: '/docusign/document_no_register',
    getapps: '/applications',
    myapps: '/myapplications',
    add_myapp: '/myapplications/add',
    docusign_document: '/docusign',
    add_docusign_document: '/docusign/docusign_document',
    update_docusign_document: '/docusign/update_document',
    transfer_docusign_document: '/document_docusign_transfer',
    app_config_record: '/myapplicationsconfig',
    remove_myapp: '/myapplications/delete',
    getmyapp: '/myapplicationsconfig/by-app-guid',
    app_tags: '/application-tags',
    documentActions: '/document-actions',
    myapp_by_guid: '/get_my_application_id_by_guid',
    get_my_application_search_by_tag: '/get_my_application/search_by',
    organization: '/organization',
    set_user_organization: '/organization/set_user_org_by_id',
    get_organization_members: '/organization/get_members',
    add_organization_members: '/organization/member/add_members',
    edit_organization_members: '/organization/member/edit_member',
    user_profile: '/user',
    docusign_token: '/docusign/fetchAccessToken',
    docusign_auth: '/docusign/accounts',
    docusign_envelope: '/docusign/accounts_envelopes',
    docusign_sending_cermony_link: '/docusign/sending_ceremony_link',
    docusign_envelope_recipient: '/docusign/envelope_view',
    verify_digest_signature: `/document-registry/verifyDigestSignature`,
    identity_exist_by_integraId: `/document-registry/identityexists`,
    get_explorer_url: `/transactions/identityexists/`,
    identity_exist_by_document_hash: `/transactions/hash-exists/`,
    defaultapplication: `/defaultapplication`,
    defaultsettings: `/defaultsettings/`,
    resetdefaultsettings: `/defaultsettings/reset`,
    system_defaults: `/defaultsettings/default`,
    default_apps: `application-tags/myapplication`,
    update_default_apps: `defaultapplication/updateAll`,
    reset_default_apps: `defaultapplication/reset`,
    dynamicform: `dynamicform`,
    documentSchema: `document-schema`,
    changePassword: '/auth/change-password',
    organization_details_by_guid: '/organization/organization_by_guid',
    document_users: '/document-users',
    document_conversations: '/document-conversations',
    messages: 'messages',
    messages_file: 'messages/upload',
    add_docusign_document_v2: 'docusign/v1/docusign_document',
    notifications: 'notifications',
    verify: 'auth/verify-otp',
    resend_otp: 'auth/send-otp',
    generate_key: 'organization/generate-keys',
    organizations_trusted: 'organizations-trusted',
    generate_signature: 'organization/generate-signature',
    get_txt_records: 'organization/get-txt-records',
    verify_signature: 'organization/verify-signature',
    templates: 'templates',
    send_verification_code: 'user/send-verification-code',
    verifiable_credentials: 'verifiable-credentials',
    verify_verifiable_credentials: 'verifiable-credentials/verify',
    find_doc_by_integra_id: 'document/findByIntergraId',
    check_doc_by_integra_id: 'docusign',
    clear_records: 'messages/clear-records',
    delete_notifications: 'notifications',
    read_notifications: 'notifications/read',
    forget_password: 'user/forgot-password',
    update_forget_Password: 'user/update-password',
    verify_token: 'verify-token',
    decrypt_comm_endpoint: 'document/decrypt-comm-endpoint',
    read_chat: 'messages/read',
    tokenized_owner: 'document/tokenized',
    transfer_owner: 'document/transfer-ownership',
    toggle_blockchain_server: 'settings/toggle-blockchain-server',
    get_signature_validation: 'pdf-utils/getSignatureInfo',
    sso_login: '/auth/sso/login',
    intel_config: 'docusign/config',
    webhook: 'webhook-logs/accounts',
    clio_generate_token: 'clio/generate-token',
    clio_refresh_token: 'clio/refresh-token',
    clio_custom_actions: 'clio/custom-actions',
    clio_document: 'clio/documents',
    clio_config: 'clio/config',
    archived_documents: 'document/archived',
    adobe_config: 'adobe-sign/config',
    adobe_generate_token: 'adobe-sign/token',
    adobe_refresh_token: 'adobe-sign/refresh',
    applications_config: 'applications-config',
    adobe_agreement: 'adobe-sign/create-agreement',
    adobe_webhook: 'adobe-sign/webhook',
    token_payment: 'user/register',
    integra_pub_key: 'user/blockchain-pubkey',
    prismatic_token: 'prismatic/generate-token',
    cyclr_token: 'cyclr/signin-token',
    generate_cyclr_token: 'cyclr/generate-token',
    integration_token: 'integration/signin-token',
    refresh_token: 'auth/refresh',
    user_tour: 'user/tour',
    cyclr_user_marketplace_token: 'cyclr/user-marketplace',
    webhooks_test: 'events-subscribe',
    document_transfer: 'document/transfer',
    cyclr_marketplace_token: 'cyclr/marketplace',
    register_verify_token: 'auth/verify',
    eventSubscribe: 'events-subscribe',
    document_conversation_status: 'document-conversations/integra',
    verifiable_presentation: 'verifiable-presentation',
    verify_verifiable_presentation: 'verifiable-presentation/verify',
    docusign_register: 'docusign/registration',
    payment_intent: 'stripe/create-payment-intent',
    get_all_schema: 'schema',
    bulk_schema: 'schema/bulk',
    user_schema: 'user-schema',
    associate_docs: 'associateDocs',
    get_all_schema_actions: 'schema/actions',

  },
};
