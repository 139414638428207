import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useReducer } from 'react';
import messages from 'src/config/messages';
import auth_axios, { authendpoints } from 'src/utils/authAxios';
// import { SET_CONTENTS, SET_DOCUMENTS } from './storeActions';

const isValidClioToken = (time) => {
  if (!time) {
    return false;
  }
  const currentTime = Date.now() / 1000;
  return time > currentTime;
};

export const ClioContext = React.createContext({
  clio_generate_token: async () => {},
  clio_refresh_token: async () => {},
  clio_custom_actions: async () => {},
  check_clio_session: async () => {},
  is_valid_clio_session: false,
  clio_access_token: null,
  clio_get_document: async () => {},
  clio_document: null,
  clio_create_smart_doc: async () => {},
  clio_config: null,
  clio_set_config: async () => {},
  clio_update_config: async () => {},
  clio_get_config: async () => {},
  clio_non_register: async () => {},
});

const SET_IS_VALID_SESSION = 'SET_IS_VALID_SESSION';
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_CLIO_DOCUMENT = 'SET_CLIO_DOCUMENT';
const SET_CLIO_CONFIG = 'SET_CLIO_CONFIG';

function clioReducer(state, action) {
  switch (action.type) {
    case SET_IS_VALID_SESSION:
      return {
        ...state,
        is_valid_clio_session: action.payload,
      };
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        clio_access_token: action.payload,
      };
    case SET_CLIO_DOCUMENT:
      return {
        ...state,
        clio_document: action.payload,
      };
    case SET_CLIO_CONFIG:
      return {
        ...state,
        clio_config: action.payload,
      };

    default:
      return state;
  }
}

function ClioContextProvider({ children }) {
  const [state, dispatch] = useReducer(clioReducer, {
    is_valid_clio_session: false,
    clio_access_token: null,
    clio_document: null,
    clio_config: null,
  });

  const clio_generate_token = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.clio_generate_token}`,
        payload
      );
      const currentTime = Date.now() / 1000;
      const expirationTime = currentTime + response.data.expires_in;
      const sessionData = {
        ...response.data,
        expiration_time: expirationTime,
      };
      const stringifiedData = JSON.stringify(sessionData);
      const encodeData = btoa(stringifiedData);
      localStorage.setItem('clio_session', encodeData);

      await check_clio_session();
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clio_refresh_token = useCallback(async (payload) => {
    try {
      localStorage.removeItem('clio_session');
      const response = await auth_axios.post(
        `${authendpoints.authnew.clio_refresh_token}`,
        payload
      );

      const currentTime = Date.now() / 1000;
      const expirationTime = currentTime + response.data.expires_in;
      const sessionData = {
        ...response.data,
        expiration_time: expirationTime,
      };
      const stringifiedData = JSON.stringify(sessionData);
      const encodeData = btoa(stringifiedData);
      localStorage.setItem('clio_session', encodeData);
      await check_clio_session();
      return sessionData;
    } catch (error) {
      console.error(error);
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clio_custom_actions = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(
        `${authendpoints.authnew.clio_custom_actions}`,
        payload
      );
      enqueueSnackbar(messages.clioCustomActionsSuccessMsg, { variant: 'success' });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
  }, []);

  const check_clio_session = useCallback(async () => {
    const encodedClioData = localStorage.getItem('clio_session');
    const decodedString = encodedClioData && atob(encodedClioData);
    const clioSession = JSON.parse(decodedString);

    if (clioSession) {
      const checkValidity = isValidClioToken(clioSession.expiration_time);
      if (checkValidity) {
        dispatch({
          type: SET_IS_VALID_SESSION,
          payload: checkValidity,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: clioSession.access_token,
        });
      } else {
        const payload = {
          refresh_token: clioSession.refresh_token,
        };
        const response = await clio_refresh_token(payload);
        const checkRefreshValidity = isValidClioToken(response.expiration_time);
        dispatch({
          type: SET_IS_VALID_SESSION,
          payload: checkRefreshValidity,
        });
        dispatch({
          type: SET_ACCESS_TOKEN,
          payload: response.access_token,
        });
      }
    } else {
      dispatch({
        type: SET_IS_VALID_SESSION,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clio_get_document = useCallback(async ({ id, token }) => {
    try {
      const response = await auth_axios.get(
        `${authendpoints.authnew.clio_document}/${id}?token=${token}`,
        {
          headers: {
            Accept: 'application/pdf',
          },
          responseType: 'blob',
        }
      );

      // Extract filename from Content-Disposition header
      const contentDisposition = response.headers.get('Content-Disposition');
      let extractedFileName = 'downloaded_clio_file.pdf';
      if (contentDisposition && contentDisposition.includes('filename=')) {
        const fileNameMatch = contentDisposition.match(/filename="?(.+)"?/);
        if (fileNameMatch.length > 1) {
          extractedFileName = fileNameMatch[1];
        }
      }

      const blob = response.data;
      const arrayBuffer = await blob.arrayBuffer();
      const pdfBytes = new Uint8Array(arrayBuffer);

      const payload = {
        pdfFileName: extractedFileName,
        pdfBytes,
      };

      dispatch({
        type: SET_CLIO_DOCUMENT,
        payload,
      });
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clio_create_smart_doc = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.clio_document}`, payload);
      enqueueSnackbar(messages.clioDocUploadSuccessMsg, { variant: 'success' });
      dispatch({
        type: SET_CLIO_DOCUMENT,
        payload: null,
      });
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      dispatch({
        type: SET_CLIO_DOCUMENT,
        payload: null,
      });
      dispatch({
        type: SET_ACCESS_TOKEN,
        payload: null,
      });
    }
  }, []);
  const clio_set_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.post(`${authendpoints.authnew.clio_config}`, payload);
      enqueueSnackbar(messages.clioPostConfigSuccessMsg, { variant: 'success' });
      dispatch({
        type: SET_CLIO_CONFIG,
        payload: response.data,
      });
      enqueueSnackbar(messages.configAddedMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
  }, []);
  const clio_update_config = useCallback(async (payload) => {
    try {
      const response = await auth_axios.patch(`${authendpoints.authnew.clio_config}`, payload);
      enqueueSnackbar(messages.clioUpdateConfigSuccessMsg, { variant: 'success' });
      dispatch({
        type: SET_CLIO_CONFIG,
        payload: response.data,
      });
      enqueueSnackbar(messages.configUpdateMsg, {
        variant: 'success',
      });
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
    }
  }, []);

  const clio_get_config = useCallback(async () => {
    try {
      const response = await auth_axios.get(authendpoints.authnew.clio_config);
      dispatch({
        type: SET_CLIO_CONFIG,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      // enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
      //   variant: 'error',
      // });
    }
  }, []);

  const clio_non_register = useCallback(async (id, payload) => {
    try {
      await auth_axios.post(`${authendpoints.authnew.clio_document}/${id}`, payload);
      return true;
    } catch (error) {
      console.error(error);
      enqueueSnackbar(Array.isArray(error?.message) ? error?.message[0] : error?.message || error, {
        variant: 'error',
      });
      return false;
    }
  }, []);

  const memoizedValue = useMemo(
    () => ({
      clio_generate_token,
      clio_refresh_token,
      clio_custom_actions,
      check_clio_session,
      is_valid_clio_session: state.is_valid_clio_session,
      clio_access_token: state.clio_access_token,
      clio_get_document,
      clio_document: state.clio_document,
      clio_create_smart_doc,
      clio_config: state.clio_config,
      clio_set_config,
      clio_update_config,
      clio_get_config,
      clio_non_register,
    }),

    [
      clio_generate_token,
      clio_refresh_token,
      clio_custom_actions,
      check_clio_session,
      state.is_valid_clio_session,
      state.clio_access_token,
      clio_get_document,
      state.clio_document,
      clio_create_smart_doc,
      state.clio_config,
      clio_set_config,
      clio_update_config,
      clio_get_config,
      clio_non_register,
    ]
  );

  return <ClioContext.Provider value={memoizedValue}>{children}</ClioContext.Provider>;
}

ClioContextProvider.propTypes = {
  children: PropTypes.node,
};

export default ClioContextProvider;
