import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';

//
import { useEffect } from 'react';
import { useIsMobile } from 'src/hooks/use-is-mobile';
import MobileScreenView from 'src/sections/error/mobile-screen-view';
import Header from './header';
import Main from './main';
import NavHorizontal from './nav-horizontal';
import NavMini from './nav-mini';
import NavVertical from './nav-vertical';

// ----------------------------------------------------------------------

export default function DashboardLayout({ children }) {
  const settings = useSettingsContext();
  const isMobile = useIsMobile();
  useEffect(() => {
    window.Intercom('update');
  }, [children]);

  const lgUp = useResponsive('up', 'lg');

  const nav = useBoolean();

  const isHorizontal = settings.themeLayout === 'horizontal';

  const isMini = settings.themeLayout === 'mini';

  const renderNavMini = <NavMini />;

  const renderHorizontal = <NavHorizontal />;

  const renderNavVertical = <NavVertical openNav={nav.value} onCloseNav={nav.onFalse} />;

  if (isHorizontal) {
    return isMobile ? (
      <MobileScreenView />
    ) : (
      <>
        <Header onOpenNav={nav.onTrue} />

        {lgUp ? renderHorizontal : renderNavVertical}

        <Main> {children}</Main>
      </>
    );
  }

  if (isMini) {
    return isMobile ? (
      <MobileScreenView />
    ) : (
      <>
        <Header onOpenNav={nav.onTrue} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          {lgUp ? renderNavMini : renderNavVertical}

          <Main> {children}</Main>
        </Box>
      </>
    );
  }

  return isMobile ? (
    <MobileScreenView />
  ) : (
    <>
      <Header onOpenNav={nav.onTrue} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        {renderNavVertical}

        <Main>
          {/* <Container maxWidth={settings.themeStretch ? false : 'xl'}>
            <Grid container spacing={3} sx={{ mb: 3 }}>
              <Grid item xs={12} md={12} sx={{ height: '230px' }}>
                <AppWelcome
                  title={`Welcome back  ${user?.name}`}
                  description={
                    <>
                      Connect <span className="second-step">by Integra Ledger</span> –your gateway
                      to <span className="third-step">Integra’s universal document and</span>{' '}
                      contract automation network.
                    </>
                  }
                />
              </Grid>
            </Grid>
          </Container> */}
          {children}
        </Main>
      </Box>
    </>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.node,
};
