import PropTypes from 'prop-types';
// @mui
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// utils
// components
import { Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs } from '@mui/material';
import JSONGrid from '@redheadphone/react-json-grid';
import { enqueueSnackbar } from 'notistack';
import { useContext, useState } from 'react';
import ReactJson from 'react-json-view';
import { useNavigate } from 'react-router';

import FileThumbnail from 'src/components/file-thumbnail';
import Iconify from 'src/components/iconify';
import {
  DocumentUserStatus,
  JSONViewTabs,
  JSONViewType,
  NotificationType,
} from 'src/config/constants';
import { useBoolean } from 'src/hooks/use-boolean';
import { useFormattedTime } from 'src/hooks/use-formatted-time';
import { paths } from 'src/routes/paths';
import { ContentContext } from 'src/store/contentContext';
import { fDateTime } from 'src/utils/format-time';
import { chatURLHandler } from 'src/utils/urlHandler';

// ----------------------------------------------------------------------

export default function NotificationItem({ notification, onCloseDrawer }) {
  const timeStamp = useFormattedTime(notification.created_at);
  const {
    update_conversation_status,
    get_notifications,
    read_notifications,
    post_message,
    fetch_messages,
  } = useContext(ContentContext);

  const navigate = useNavigate();
  const openDialog = useBoolean();
  const isSubmitting = useBoolean();
  const [showJson, setShowJson] = useState(true);
  const [selectedTab, setSelectedTab] = useState(JSONViewType.GRID);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const parseMessage =
    notification.notification_type === NotificationType.JSON_MESSAGE
      ? JSON.parse(notification?.message?.message)
      : notification?.message?.message;

  const renderDialogContent = (
    <DialogContent
      sx={{
        position: 'relative',
      }}
    >
      <Tabs value={selectedTab} onChange={handleChange}>
        {JSONViewTabs.map((tab, index) => (
          <Tab label={tab.label} value={tab.value} key={index} />
        ))}
      </Tabs>
      <Box
        sx={{
          my: 2,
        }}
      >
        {selectedTab === JSONViewType.GRID && (
          <JSONGrid
            data={parseMessage?.data}
            theme="softEra"
            customTheme={{
              numberColor: 'black',
              stringColor: 'black',
              booleanColor: 'black',
            }}
          />
        )}
        {selectedTab === JSONViewType.JSON && <ReactJson src={parseMessage?.data} />}
      </Box>
    </DialogContent>
  );

  const renderAvatar = (
    <ListItemAvatar>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
        }}
      >
        <Box
          component="img"
          src={`/assets/icons/notification/${
            (notification.notification_type === NotificationType.DEFAULT && 'ic_order') ||
            (notification.notification_type === NotificationType.REQUEST && 'ic_mail') ||
            (notification.notification_type === NotificationType.MESSAGE && 'ic_chat') ||
            (notification.notification_type === NotificationType.MESSAGE_ATTACHMENT && 'ic_chat') ||
            (notification.notification_type === NotificationType.JSON_MESSAGE && 'ic_chat') ||
            (notification.notification_type === NotificationType.MORE_DETAILS && 'ic_chat') ||
            (notification.notification_type === NotificationType.TRANSFER && 'ic_chat') ||
            (notification.notification_type === NotificationType.DOCUMENT_REGISTER && 'ic_chat')
          }.svg`}
          sx={{ width: 24, height: 24 }}
        />
      </Stack>
    </ListItemAvatar>
  );

  const renderText = (
    <ListItemText
      disableTypography
      primary={reader(notification.content)}
      secondary={
        <Stack
          direction="row"
          alignItems="center"
          sx={{ typography: 'caption', color: 'text.disabled' }}
          divider={
            <Box
              sx={{
                width: 2,
                height: 2,
                bgcolor: 'currentColor',
                mx: 0.5,
                borderRadius: '50%',
              }}
            />
          }
        >
          {/* {fToNow(notification.created_at)} */}
          {timeStamp}
          {(notification.notification_type === NotificationType.DEFAULT &&
            'Document Communication') ||
            (notification.notification_type === NotificationType.REQUEST && 'Connection Request') ||
            (notification.notification_type === NotificationType.MESSAGE && 'Text Message') ||
            (notification.notification_type === NotificationType.MESSAGE_ATTACHMENT &&
              'File Attachment') ||
            (notification.notification_type === NotificationType.JSON_MESSAGE && 'JSON Message') ||
            (notification.notification_type === NotificationType.MORE_DETAILS && 'More Details') ||
            (notification.notification_type === NotificationType.TRANSFER && 'Document Name') ||
            (notification.notification_type === NotificationType.DOCUMENT_REGISTER &&
              'Document Register')}
        </Stack>
      }
    />
  );

  const renderUnReadBadge = !notification.is_read && (
    <Box
      sx={{
        top: 26,
        width: 8,
        height: 8,
        right: 20,
        borderRadius: '50%',
        bgcolor: 'info.main',
        position: 'absolute',
      }}
    />
  );

  const handleClick = async (option) => {
    await update_conversation_status(
      option,
      notification?.document_conversation?.document_conversation_guid
    );
    get_notifications();
  };

  const handleRequestInfo = async (option) => {
    console.log('enter in function');
    try {
      isSubmitting.onTrue();

      const formData = new FormData();
      const message = {
        request_more_info: true,
      };

      // Append the message and type to formData
      formData.append('message', JSON.stringify(message));
      formData.append('type', 'object');

      const documentConversationId = notification?.document_conversation_id;

      // if (documentConversationId) {
      formData.append('document_conversations_id', documentConversationId);

      await post_message(formData, notification?.document_conversation_id);
      const payload = {
        notification_ids: [notification.id],
        is_more_info_requested: true,
      };
      await read_notifications(payload);

      formData.forEach((value, key) => {
        console.log(`Form Data for chat approval ----------- ${key}: ${value}`);
      });
    } catch (error) {
      console.error('Error posting message:', error);
    } finally {
      isSubmitting.onFalse();
      // isMoreInfoRequested = true;
      fetch_messages(notification?.document_conversation_id);
    }
  };

  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();

      const a = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = fileName;

      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading file:', error);
      enqueueSnackbar('Unable to download !', { variant: 'error' });
    }
  };

  const friendAction = () => {
    if (notification?.document_conversation?.status === DocumentUserStatus.APPROVED) {
      return (
        <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
          <Button size="small" variant="outlined" color="inherit" disabled>
            Accepted
          </Button>
        </Stack>
      );
    }
    if (notification?.document_conversation?.status === DocumentUserStatus.DECLINE) {
      return (
        <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
          <Button size="small" variant="outlined" color="inherit" disabled>
            Declined
          </Button>
        </Stack>
      );
    }

    return (
      <Stack spacing={1} direction="row" sx={{ mt: 1.5 }}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={() => {
            handleClick(DocumentUserStatus.APPROVED);
          }}
        >
          Accept
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            handleClick(DocumentUserStatus.DECLINE);
          }}
        >
          Decline
        </Button>
        <Button
          size="small"
          variant="outlined"
          disabled={notification?.is_more_info_requested === 2}
          onClick={() => {
            handleRequestInfo(DocumentUserStatus.REQUEST_MORE_INFO);
          }}
        >
          {notification?.is_more_info_requested === 2 ? 'More Info Requested' : 'Request More Info'}
        </Button>
      </Stack>
    );
  };

  const projectAction = (
    <Stack alignItems="flex-start">
      <Box
        sx={{
          p: 1.5,
          my: 1.5,
          borderRadius: 1.5,
          color: 'text.secondary',
          bgcolor: 'background.neutral',
        }}
      >
        {reader(`<p>${notification?.message?.message}</p>`)}
      </Box>

      <Button
        size="small"
        variant="contained"
        onClick={() => {
          onCloseDrawer();
          navigate(
            `${paths.dashboard.chat}${
              notification?.document_conversation_guid
                ? `?guid=${notification?.document_conversation_guid}`
                : ''
            }`
          );
        }}
      >
        Reply
      </Button>
    </Stack>
  );

  const fileAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
        '&:hover': {
          bgcolor: 'background.default',
        },
      }}
    >
      <FileThumbnail sx={{ width: 40, height: 40 }} />

      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        flexGrow={1}
        flexWrap="wrap"
        sx={{ minWidth: 0 }}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                color: 'text.secondary',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {notification?.message?.document?.document_name}
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled', flexWrap: 'wrap' }}
              // divider={
              //   <Box
              //     sx={{
              //       mx: 0.5,
              //       width: 2,
              //       height: 2,
              //       borderRadius: '50%',
              //       bgcolor: 'currentColor',
              //     }}
              //   />
              // }
            >
              <span>{notification?.message?.document?.document_type}</span>
              <span>{fDateTime(notification?.message?.document?.created_at)}</span>
            </Stack>
          }
        />

        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            handleDownload(
              chatURLHandler(notification?.message?.message),
              notification?.message?.document?.document_name
            );
          }}
        >
          Download
        </Button>
      </Stack>
    </Stack>
  );

  const jsonAction = (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        pl: 1,
        p: 1.5,
        mt: 1.5,
        borderRadius: 1.5,
        bgcolor: 'background.neutral',
        '&:hover': {
          bgcolor: 'background.default',
        },
      }}
    >
      <Button
        variant="soft"
        color="info"
        onClick={() => {
          openDialog.onTrue();
        }}
      >
        <Iconify icon="bi:filetype-json" />
      </Button>

      <Stack
        spacing={1}
        direction={{ xs: 'column', sm: 'row' }}
        flexGrow={1}
        flexWrap="wrap"
        sx={{ minWidth: 0 }}
      >
        <ListItemText
          disableTypography
          primary={
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                color: 'text.secondary',
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                wordBreak: 'break-word',
              }}
            >
              {parseMessage?.action_name}
            </Typography>
          }
          secondary={
            <Stack
              direction="row"
              alignItems="center"
              sx={{ typography: 'caption', color: 'text.disabled', flexWrap: 'wrap' }}
              divider={
                <Box
                  sx={{
                    mx: 0.5,
                    width: 2,
                    height: 2,
                    borderRadius: '50%',
                    bgcolor: 'currentColor',
                  }}
                />
              }
            >
              <span>Communication Attached</span>
              <span>{fDateTime(notification?.message?.created_at)}</span>
            </Stack>
          }
        />

        <Button
          size="small"
          variant="outlined"
          onClick={() => {
            openDialog.onTrue();
          }}
        >
          View
        </Button>
      </Stack>
    </Stack>
  );

  // const handleReadClick = async () => {
  //   if (notification.is_read) {
  //     return;
  //   }
  //   const payload = {
  //     notification_ids: [notification.id],
  //   };
  //   await read_notifications(payload);
  // };

  const handleReadClick = async () => {
    const conversationGuid = notification?.document_conversation?.document_conversation_guid;
    // Always allow redirection, even if the notification is already read
    if (conversationGuid) {
      navigate(`/dashboard/chat?guid=${conversationGuid}`);
      onCloseDrawer(false);
    }
    if (notification.notification_type === NotificationType.DOCUMENT_REGISTER) {
      // navigate('/dashboard/file-manager');
      navigate(paths.dashboard.fileManager);
    }

    // If the notification is already read, skip marking it as read
    if (notification.is_read) {
      return;
    }

    // Otherwise, mark the notification as read
    const payload = {
      notification_ids: [notification.id],
    };
    await read_notifications(payload);
  };

  return (
    <ListItemButton
      disableRipple
      sx={{
        p: 2.5,
        alignItems: 'flex-start',
        borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
      }}
      onClick={handleReadClick}
    >
      {renderUnReadBadge}

      {renderAvatar}

      <Stack sx={{ flexGrow: 1 }}>
        {renderText}
        {notification.notification_type === NotificationType.REQUEST && friendAction()}
        {notification.notification_type === NotificationType.MESSAGE && projectAction}
        {notification.notification_type === NotificationType.MESSAGE_ATTACHMENT && fileAction}
        {notification.notification_type === NotificationType.JSON_MESSAGE && jsonAction}
        {/* {notification.notification_type === NotificationType.TRANSFER && jsonAction} */}
      </Stack>

      <Dialog
        open={openDialog.value}
        onClose={openDialog.onFalse}
        scroll="paper"
        fullWidth
        maxWidth="md"
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pr: 2 }}>
          <DialogTitle>{parseMessage?.action_name}</DialogTitle>
          {/* <Button
            onClick={() => setShowJson(!showJson)}
            size="small"
            variant="contained"
            color="primary"
          >
            {showJson ? 'Show Grid' : 'Show JSON'}
          </Button> */}
        </Box>
        {renderDialogContent}

        <DialogActions>
          <Button onClick={openDialog.onFalse} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </ListItemButton>
  );
}

NotificationItem.propTypes = {
  notification: PropTypes.object,
  onCloseDrawer: PropTypes.func,
};

// ----------------------------------------------------------------------

function reader(data) {
  return (
    <Box
      dangerouslySetInnerHTML={{ __html: data }}
      sx={{
        mb: 0.5,
        '& p': { typography: 'body2', m: 0, wordBreak: 'break-word' },
        '& a': { color: 'inherit', textDecoration: 'none' },
        '& strong': { typography: 'subtitle2' },
      }}
    />
  );
}
