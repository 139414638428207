import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
//
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from 'react-oidc-context';
import { TourProvider } from '@reactour/tour'

import { OIDC_CONFIG_SETTINGS } from 'src/config/constants';
import App from './App';

// ----------------------------------------------------------------------

const steps = [
  {
    selector: '.first-step',
    content: 'This is my first Step',
  },
  {
    selector: '.second-step',
    content: 'This is the second Step',
  },
  {
    selector: '.third-step',
    content: 'This is the third Step',
  },
  // ...
]

const root = ReactDOM.createRoot(document.getElementById('root'));

const onSigninCallback = (_user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  ...OIDC_CONFIG_SETTINGS,
  onSigninCallback, // NOTE - This cleans the URL after login
  // ...
};

root.render(
  <AuthProvider {...oidcConfig}>
  <TourProvider steps={steps}>
    <HelmetProvider>
      <BrowserRouter>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
    </TourProvider>
  </AuthProvider>
);
