import { useContext, useEffect, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';

import { useAuthContext } from 'src/auth/hooks';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { SHARED_SWITCHBOARD } from 'src/config-globals';
import { ContentContext } from 'src/store/contentContext';

// ----------------------------------------------------------------------

const icon = (name, color) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} color={color} />
);
const iconify = (name) => <Iconify icon={name} width={25} />;

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  token: icon('ic_integ', '#F7B700'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  create: iconify('ion:create'),
  import: iconify('solar:import-bold-duotone'),
  apps: iconify('ri:apps-2-fill'),
  intel: iconify('cib:intel'),
  clio: icon('clio_glyph_black'),
  eSignature: iconify('lucide:file-signature'),
  crypto: iconify('arcticons:crypto-prices'),
  paypal: iconify('mage:dollar-fill'),
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useLocales();
  const { user } = useAuthContext();
  const { myapplications, fetch_myapplications } = useContext(ContentContext);

  useEffect(() => {
    fetch_myapplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const intelApp = myapplications.find((app) => app?.application?.app_name === 'Intel') ?? null;
  const intelAppPath = intelApp ? paths.dashboard.myapps.details(intelApp.app_guid) : paths.page404;
  const intelUser = user?.email.includes('@intel.com') || user?.email.includes('@yopmail.com');

  const data = useMemo(
    () => [
      // Smart Documents
      // ----------------------------------------------------------------------
      {
        subheader: t('smart_documents'),
        items: [
          {
            title: t('Verify & Register'),
            path: paths.dashboard.general.documentdetails,
            icon: ICONS.create,
          },
          {
            title: t('Registrations'),
            path: paths.dashboard.fileManager,
            icon: ICONS.folder,
          },
          {
            title: t('My Tokens'),
            path: paths.dashboard.tokenizedOwner,
            icon: ICONS.token,
          },

          {
            title: t('Communications'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },
          // {
          //   title: t('e-Signature'),
          //   path: paths.dashboard.eSignature,
          //   icon: ICONS.eSignature,
          // },

          ...(!SHARED_SWITCHBOARD || intelUser
            ? [
                {
                  title: t('Intel'),
                  // path: intelAppPath,
                  path: paths.dashboard.intel.root,
                  icon: ICONS.intel,
                },
              ]
            : []),

          // {
          //   title: t('clio'),
          //   path: paths.dashboard.clio.root,
          //   icon: ICONS.clio,
          // },
        ],
      },
      // * Uncommemnt this to show communications tabs
      // {
      //   subheader: t('communications'),
      //   items: [
      //     {
      //       title: t('documents'),
      //       // path: paths.dashboard.user.list,
      //       path: paths.dashboard.general.channels,
      //       icon: ICONS.chat,
      //     },
      //     {
      //       title: t('contacts'),
      //       path: paths.dashboard.contacts,
      //       icon: ICONS.blog,
      //     },
      //   ],
      // },
      {
        subheader: t('app_store'),
        items: [
          // {
          //   title: t('Apps'),
          //   path: paths.dashboard.apps.root,
          //   icon: ICONS.apps,
          // },
          {
            title: t('Apps'),
            path: paths.dashboard.cyclrMarketplace,
            icon: ICONS.apps,
          },
          // {
          //   title: t('My Apps'),
          //   path: paths.dashboard.myapps.root,
          //   icon: ICONS.job,
          //   children:
          //     myapplications.length > 0
          //       ? myapplications.map((app, index) => ({
          //           title: app?.application?.app_name,
          //           path: paths.dashboard.myapps.details(app?.app_guid),
          //         }))
          //       : [],
          // },
        ],
      },

      {
        subheader: t('Payments'),
        items: [
          {
            title: t('Traditional'),
            path: `${paths.dashboard.payments}?tab=traditional`,
            icon: ICONS.paypal,
          },
          {
            title: t('Cryptocurrency'),
            path: `${paths.dashboard.payments}?tab=crypto`,
            icon: ICONS.crypto,
          },
        ],
      },
      {
        subheader: t('blockchain'),
        items: [
          // {
          //   title: t('verify'),
          //   path: paths.dashboard.general.verifysmartdoc,
          //   icon: ICONS.label,
          // },

          {
            title: t('explorer'),
            externalLink: true,
            path: 'https://explorer.integraledger.com',
            icon: ICONS.menuItem,
          },
        ],
      },
      // {
      //   subheader: t('management'),
      //   items: [
      //     // USER
      //     {
      //       title: t('user'),
      //       path: paths.dashboard.user.root,
      //       icon: ICONS.user,
      //       children: [
      //         { title: t('profile'), path: paths.dashboard.user.root },
      //         { title: t('cards'), path: paths.dashboard.user.cards },
      //         { title: t('list'), path: paths.dashboard.user.list },
      //         { title: t('create'), path: paths.dashboard.user.new },
      //         { title: t('edit'), path: paths.dashboard.user.demo.edit },
      //         { title: t('account'), path: paths.dashboard.user.account },
      //       ],
      //     },
      //   ],
      // },

      // Document Messaging
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('document_messaging'),
      //   items: [],
      // },

      // Settings
      // {
      //   subheader: t(t('settings')),
      //   items: [
      //     {
      //       title: t('list'),
      //       path: paths.dashboard.user.list,
      //       icon: ICONS.banking,

      //     },

      //     {
      //       title: t('create'),
      //       path: paths.dashboard.user.new,
      //       icon: ICONS.banking,

      //     },

      //     {
      //       title: t('edit'),
      //       path: paths.dashboard.user.demo.edit,
      //       icon: ICONS.banking,

      //     },

      //     {
      //       title: t('account'),
      //       path: paths.dashboard.user.account,
      //       icon: ICONS.banking,

      //     },
      //   ],
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [myapplications, t]
    // [t]
  );

  return data;
}
