import PropTypes from 'prop-types';
// @mui
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
// theme
import { bgBlur } from 'src/theme/css';
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
import SvgColor from 'src/components/svg-color';
//
import { useContext, useEffect, useState } from 'react';
import { useAuthContext } from 'src/auth/hooks';
import Image from 'src/components/image';
import { ContentContext } from 'src/store/contentContext';
import { demoImgURLHandler, orgURLHandler } from 'src/utils/urlHandler';
// import { AccountPopover, NotificationsPopover } from '../_common';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { AdminContext } from 'src/store/adminContext';
import AccountPopover from '../_common/account-popover';
import NotificationsPopover from '../_common/notifications-popover/notifications-popover';
import { HEADER, NAV } from '../config-layout';

// ----------------------------------------------------------------------

const IMAGES = {
  LOGO: '/assets/logos/integra_logo.png',
  MENU_ITEM: '/assets/icons/navbar/ic_menu_item.svg',
};

export default function Header({ onOpenNav }) {
  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const settings = useSettingsContext();
  const isNavHorizontal = settings.themeLayout === 'horizontal';
  const isNavMini = settings.themeLayout === 'mini';
  const lgUp = useResponsive('up', 'lg');
  const offset = useOffSetTop(HEADER.H_DESKTOP);
  const offsetTop = offset && !isNavHorizontal;
  const { system_defaults, user, is_mainnet_network, get_blockchain_server } = useAuthContext();
  const { org_data } = useContext(ContentContext);
  const { toggle_blockchain_server } = useContext(AdminContext);
  const [isMainnet, setIsMainnet] = useState(is_mainnet_network);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    get_blockchain_server();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleToggle = async () => {
    await toggle_blockchain_server();
  };
  useEffect(() => {
    setIsMainnet(is_mainnet_network);
  }, [is_mainnet_network]);

  const renderCenterLogo = () => {
    if (org_data?.organization_image) {
      return (
        <img
          src={orgURLHandler(org_data?.organization_image)}
          height={50}
          alt={orgURLHandler(org_data?.organization_image)}
        />
      );
    }
    if (user?.organization?.organization_image) {
      return (
        <img
          src={orgURLHandler(user?.organization?.organization_image)}
          height={50}
          alt={orgURLHandler(user?.organization?.organization_image)}
        />
      );
    }
    if (system_defaults) {
      return (
        <img
          src={demoImgURLHandler(system_defaults?.logo)}
          height={50}
          alt={demoImgURLHandler(system_defaults?.logo)}
        />
      );
    }
    return <Image alt="integra_logo.png" src={IMAGES.LOGO} width={90} />;
  };

  // const renderAlert = () => {
  //   enqueueSnackbar(`You're currently on Testnet`, {
  //     persist: true,
  //     anchorOrigin: { vertical: 'top', horizontal: 'center' },
  //     variant: 'warning',
  //   });
  // };

  const renderContent = (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ width: '100%' }}
    >
      {/* Left Section */}
      <Stack direction="row" alignItems="center">
        {!lgUp && (
          <IconButton onClick={onOpenNav}>
            <SvgColor src={IMAGES.MENU_ITEM} />
          </IconButton>
        )}
      </Stack>

      {/* Centered Logo */}
      {!lgUp && renderCenterLogo()}
      {/* {user?.role !== UserType.ADMIN && !isMainnet && (
        <Box
          component={Paper}
          px={1}
          py={0.5}
          variant="outlined"
          bgcolor="#FFE286"
          sx={{
            position: 'fixed',
            top: !lgUp ? '12px' : '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
          }}
        >
          <Typography variant="caption" fontSize="0.85rem">
            Connected to Integra <strong>Testnet</strong>
          </Typography>
        </Box>
      )} */}

      {/* Right Section */}
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        {/* {user?.role === UserType.ADMIN && SHARED_SWITCHBOARD && (
          <StyledToggleContainer>
            <StyledToggleSubContainer>
              <StyledToggleBox isMainnet={isMainnet} />
              <StyledToggleButton
                disableRipple
                variant="text"
                isMainnet={isMainnet}
                onClick={handleToggle}
              >
                {BLOCKCHAIN_NET_TYPE.MAIN_NET}
              </StyledToggleButton>
              <StyledToggleButton
                disableRipple
                variant="text"
                isMainnet={!isMainnet}
                onClick={handleToggle}
              >
                {BLOCKCHAIN_NET_TYPE.TEST_NET}
              </StyledToggleButton>
            </StyledToggleSubContainer>
          </StyledToggleContainer>
        )} */}

        {/* {user && user?.role !== UserType.ORG && !isMainnet && renderAlert()} */}

        <NotificationsPopover />
        <AccountPopover />
      </Stack>
    </Stack>
  );

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE + HEADER.H_NETWORK_BANNER,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP + HEADER.H_NETWORK_BANNER,
          ...(offsetTop &&
            {
              // height: HEADER.H_DESKTOP_OFFSET,
            }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            // height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Box sx={{ background: 'blue' }}>
        <Typography variant="subtitle2" sx={{ textAlign: 'center', color: 'white', padding: 1 }}>
          You are using the Integra Ledger Testnet. Click
          <Button
            onClick={handleOpenModal}
            sx={{
              color: 'white',
              textDecoration: 'underline',
              padding: '0', // Remove padding
              minWidth: 'auto', // Adjust width
              height: 'auto', // Ensure height is minimal
              margin: '0 4px', // Control horizontal spacing
            }}
          >
            HERE
          </Button>
          to learn more.
        </Typography>
      </Box>
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>

      {/* Modal Component */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Integra Testnet Information</DialogTitle>
        <DialogContent>
          <Typography>
            The Integra Ledger Testnet is a sandbox environment for testing purposes only. It allows
            you to experiment with the technology and ensure everything functions as expected.
            However, please note that documents on the Testnet are not valid for official use or
            authentication purposes. The Testnet is intended for trial and development to verify
            system behavior before deploying to the main network.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
Header.propTypes = {
  onOpenNav: PropTypes.func,
};
