import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { RouterLink } from 'src/routes/components';
import { paths } from 'src/routes/paths';
// auth
import { useAuthContext } from 'src/auth/hooks';
// assets
import { PasswordIcon } from 'src/assets/icons';
// components
import { IconButton, InputAdornment } from '@mui/material';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';

import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useBoolean } from 'src/hooks/use-boolean';
import regex from 'src/regex';
// ----------------------------------------------------------------------

export default function JwtUpdatePasswordView() {
  const navigate = useNavigate();
  const location = useLocation();
  // const isUpdatePassword = location.pathname.includes('jwt');
  // const isForgetPassword = location.pathname.includes('forgot-password');
  const { update_password, update_forget_password, verify_token } = useAuthContext();
  const queryParams = new URLSearchParams(location.search);
  const tokenParam = queryParams.get('token');

  const newPassword = useBoolean();
  const confirmNewPassword = useBoolean();

  const ForgotPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Password is required')
      .matches(
        regex.PASSWORD,
        'Password must be at least 8 characters, include at least one uppercase letter, one number, one special character.'
      ),
      confirmNewPassword: Yup.string()
      .required('Confirm password is required')
      .oneOf([Yup.ref('newPassword')], 'Passwords must match'),
  });

  const defaultValues = {
    newPassword: '',
    confirmNewPassword: '',
  };

  useEffect(() => {
    const checkVerifiedToken = async () => {
      const response = await verify_token(tokenParam);
      if (response.status === false) {
        navigate(paths.page404);
      }
    };

    if (tokenParam) {
      checkVerifiedToken();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verify_token]);

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      currentPassword: data.currentPassword,
      newPassword: data.newPassword,
      confirmNewPassword: data.confirmNewPassword,
    };
    if (tokenParam) {
      const modifiedpayload = {
        password: data.newPassword,
        token: tokenParam,
      };
      await update_forget_password(modifiedpayload);
    } else {
      await update_password(payload);
    }



    // const payload = {
    //   currentPassword: data.currentPassword,
    //   newPassword: data.newPassword,
    //   confirmNewPassword: data.confirmNewPassword,
    // };
    // try {
    //   await update_password(payload);
     
    // } catch (error) {
    //   console.error(error);
    // }

  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField
        name="newPassword"
        label="Password"
        type={newPassword.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={newPassword.onToggle} edge="end">
                <Iconify icon={newPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <RHFTextField
        name="confirmNewPassword"
        label="Confirm New Password"
        type={confirmNewPassword.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={confirmNewPassword.onToggle} edge="end">
                <Iconify
                  icon={confirmNewPassword.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Update Password
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.auth.frontpage}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Update your password</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Please enter the new password for your account
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}
    </FormProvider>
  );
}
