// import React, { useContext, useEffect, useState } from 'react';
// import ReactPlayer from 'react-player/vimeo';
// import { ContentContext } from 'src/store/contentContext';
// import { useAuthContext } from 'src/auth/hooks';
// import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

// const VideoPlayer = (props) => {
//   // eslint-disable-next-line react/prop-types
//   const { is_tour_video_completed } = props;
//   const [open, setOpen] = useState(false);
//   const { user_tour } = useContext(ContentContext);
//   const { user } = useAuthContext();

//   const onConfigClick = async () => {
//     try {
//       await user_tour({ type: 1 });
//       setOpen(true); // Open the dialog only after API call is successful
//     } catch (error) {
//       console.error('Error calling user_tour API:', error);
//     }
//   };

//   useEffect(() => {
//     if (is_tour_video_completed === false) {
//       onConfigClick();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [user]);

//   return (
//     <Box style={{ maxWidth: '100%', margin: 0 }}>
//       <Dialog
//         open={open}
//         onClose={() => setOpen(false)}
//         aria-labelledby="alert-dialog-title"
//         aria-describedby="alert-dialog-description"
//         maxWidth="md"
//         fullWidth
//         PaperProps={{
//           style: {
//             margin: 0,
//             width: '100%',
//             height: '100%',
//             maxHeight: '50%',
//             padding: 0,
//           },
//         }}
//       >
//         <DialogTitle id="alert-dialog-title" sx={{ position: 'relative' }}>
//           <IconButton
//             edge="end"
//             color="inherit"
//             onClick={() => setOpen(false)}
//             sx={{ position: 'absolute', right: 12, top: 8 }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </DialogTitle>
//         <DialogContent
//           sx={{
//             width: '100%',
//             height: '100%',
//             padding: '16px',
//             boxSizing: 'border-box',
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//           }}
//         >
//           <ReactPlayer
//             url="https://vimeo.com/137925379"
//             controls
//             width="100%"
//             height="100%"
//             style={{
//               maxWidth: 'calc(100% - 16px)',
//               maxHeight: 'calc(100% - 32px)',
//               marginBottom: '16px',
//             }} // Adjust to respect padding
//           />
//         </DialogContent>
//       </Dialog>
//     </Box>
//   );
// };

// export default VideoPlayer;

import React, { useContext, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { ContentContext } from 'src/store/contentContext';
import { useAuthContext } from 'src/auth/hooks';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const VideoPlayer = (props) => {
  // eslint-disable-next-line react/prop-types
  const { video } = props;
  console.log('🚀 ~ VideoPlayer ~ video:', video);
  const [open, setOpen] = useState(false);
  const { user_tour } = useContext(ContentContext);
  const { user } = useAuthContext();

  const onConfigClick = async () => {
    try {
      // await user_tour({ type: 1 });
      setOpen(true); // Open the dialog after API call
    } catch (error) {
      console.error('Error calling user_tour API:', error);
    }
  };

  // Check if the video URL is empty or invalid
  // eslint-disable-next-line react/prop-types
  const isValidVideo = typeof video === 'string' && video.trim().length > 0;
  console.log('video', video);

  return (
    <Box style={{ maxWidth: '100%', margin: 0 }}>
      {/* Only show the button if the video URL is not empty */}
      {isValidVideo && (
        <Button variant="contained" color="primary" onClick={onConfigClick}>
          Instructional Video
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            margin: 0,
            width: '100%',
            height: '100%',
            maxHeight: '50%',
            padding: 0,
          },
        }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ position: 'relative' }}>
          <IconButton
            edge="end"
            color="inherit"
            onClick={() => setOpen(false)}
            sx={{ position: 'absolute', right: 12, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            width: '100%',
            height: '100%',
            padding: '16px',
            boxSizing: 'border-box',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <ReactPlayer
            url={video}
            controls
            width="100%"
            height="100%"
            style={{
              maxWidth: 'calc(100% - 16px)',
              maxHeight: 'calc(100% - 32px)',
              marginBottom: '16px',
            }}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VideoPlayer;
