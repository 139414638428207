import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { RouterLink } from 'src/routes/components';
import { useRouter, useSearchParams } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
// config
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// auth
import { useAuthContext } from 'src/auth/hooks';
// components
import PropTypes from 'prop-types';
import { useAuth } from 'react-oidc-context';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
import Iconify from 'src/components/iconify';
import regex from 'src/regex';
import { FRONTEND_SERVER_BASE_URL } from 'src/config-globals';
// ----------------------------------------------------------------------

export default function JwtLoginView({ file, register, altUI, onRegisterButtonClick }) {
  console.log('🚀 ~ JwtLoginView ~ onRegisterButtonClick>>>>', onRegisterButtonClick);
  console.log('🚀 ~ JwtLoginView ~ altUI>>>>', altUI);
  console.log('🚀 ~ JwtLoginView ~ register>>>>', register);
  console.log('🚀 ~ JwtLoginView ~ file>>>>', file);
  const { login } = useAuthContext();

  const router = useRouter();

  const [errorMsg, setErrorMsg] = useState('');

  const searchParams = useSearchParams();

  const returnTo = searchParams.get('returnTo');

  const password = useBoolean();

  const forwardedFile = file ?? null;
  console.log('🚀 ~ JwtLoginView ~ forwardedFile:', forwardedFile);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .required('Email is required')
      .matches(regex.EMAIL, 'Email must be a valid email address'),
    password: Yup.string().trim().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const auth = useAuth();
  const onSubmit = handleSubmit(async (data) => {
    console.log('1111111111111111');
    try {
      // auth.signinRedirect();
      const trimmedEmail = data.email.trim();
      const trimmedPassword = data.password.trim();
      const app_url = `${FRONTEND_SERVER_BASE_URL}/auth/jwt/`;
      await login?.(trimmedEmail, trimmedPassword, app_url, forwardedFile, register);

      // router.push(returnTo || PATH_AFTER_LOGIN);
    } catch (error) {
      console.error(error);
      reset();
      setErrorMsg(typeof error === 'string' ? error : error.message);
    }
  });

  const handleOIDCClick = async () => {
    await auth.signinRedirect();
    // await auth.signinPopup();
  };
  // const handleOIDLogoutCClick = async () => {
  //   await auth.removeUser();
  //   await auth.revokeTokens();
  //   await auth.signoutRedirect();
  //   // await auth.signinPopup();
  // };

  const renderHead = (
    <Stack spacing={2} sx={{ mb: 5 }}>
      <Typography variant="h4">Sign in to Integra Connect</Typography>

      {!altUI && (
        <Stack direction="row" spacing={0.5}>
          <Typography variant="body2">New user?</Typography>

          <Link component={RouterLink} href={paths.auth.jwt.register} variant="subtitle2">
            Create an account
          </Link>
        </Stack>
      )}
    </Stack>
  );

  const renderForm = (
    <Stack spacing={2.5}>
      {!!errorMsg && <Alert severity="error">{errorMsg}</Alert>}

      <RHFTextField
        name="email"
        label="Email address"
        InputLabelProps={{ shrink: true }}
        // inputProps={{ //* Uncomment this code if you want to stop autocomplete on sign in form
        //   autocomplete: 'off',
        //   form: {
        //     autocomplete: 'off',
        //   },
        // }}
      />

      <RHFTextField
        name="password"
        label="Password"
        type={password.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
      />

      <Link
        variant="body2"
        component={RouterLink}
        href={paths.auth.jwt.forgotPassword}
        color="inherit"
        underline="always"
        sx={{ alignSelf: 'flex-end' }}
      >
        Forgot password?
      </Link>

      <LoadingButton
        // fullWidth
        color="primary"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        sx={{ width: 'fit-content', alignSelf: 'center', px: 6 }}
      >
        Login
      </LoadingButton>
      {/* <LoadingButton
        // fullWidth
        color="primary"
        size="large"
        onClick={handleOIDCClick}
        variant="soft"
        loading={isSubmitting}
        sx={{ width: 'fit-content', alignSelf: 'center', px: 3 }}
      >
        Login with SSO
      </LoadingButton> */}
      {/* <LoadingButton
        // fullWidth
        color="warning"
        size="large"
        onClick={handleOIDLogoutCClick}
        variant="soft"
        loading={isSubmitting}
        sx={{ width: 'fit-content', alignSelf: 'center', px: 3 }}
      >
        Logout from SSO
      </LoadingButton> */}
    </Stack>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {renderHead}

      {renderForm}

      {altUI && (
        <Stack mt={2} justifyContent="center" alignItems="center">
          <Stack direction="row" spacing={0.5}>
            <Typography variant="body2">No Account?</Typography>
            <Link
              component={RouterLink}
              onClick={() => {
                onRegisterButtonClick(true);
              }}
              variant="body2"
            >
              click here
            </Link>
            <Typography variant="body2">to create one</Typography>
          </Stack>
        </Stack>
      )}
    </FormProvider>
  );
}

JwtLoginView.propTypes = {
  file: PropTypes.instanceOf(File),
  register: PropTypes.bool,
  altUI: PropTypes.bool,
  onRegisterButtonClick: PropTypes.func,
};
