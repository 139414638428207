// import * as Yup from 'yup';
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// // @mui
// import LoadingButton from '@mui/lab/LoadingButton';
// import Link from '@mui/material/Link';
// import Stack from '@mui/material/Stack';
// import Typography from '@mui/material/Typography';
// // routes
// import { paths } from 'src/routes/paths';
// import { useRouter } from 'src/routes/hooks';
// import { RouterLink } from 'src/routes/components';
// // auth
// import { useAuthContext } from 'src/auth/hooks';
// // assets
// import { PasswordIcon } from 'src/assets/icons';
// // components
// import Iconify from 'src/components/iconify';
// import { FRONTEND_SERVER_BASE_URL } from 'src/config-globals';

// import FormProvider, { RHFTextField } from 'src/components/hook-form';

// // ----------------------------------------------------------------------

// export default function JwtForgotPasswordView() {
//   const { forget_password } = useAuthContext();

//   const ForgotPasswordSchema = Yup.object().shape({
//     email: Yup.string().required('Email is required').email('Email must be a valid email address'),
//   });

//   const defaultValues = {
//     email: '',
//   };

//   const methods = useForm({
//     resolver: yupResolver(ForgotPasswordSchema),
//     defaultValues,
//   });

//   const {
//     handleSubmit,
//     formState: { isSubmitting },
//   } = methods;

//   const onSubmit = handleSubmit(async (data) => {
//     const payload = {
//       email: data.email,
//       app_url: `${FRONTEND_SERVER_BASE_URL}`,
//     };
//     await forget_password(payload);
//   });

//   const renderForm = (
//     <Stack spacing={3} alignItems="center">
//       <RHFTextField name="email" label="Email address" />

//       <LoadingButton
//         fullWidth
//         size="large"
//         type="submit"
//         variant="contained"
//         loading={isSubmitting}
//       >
//         Send Request
//       </LoadingButton>

//       <Link
//         component={RouterLink}
//         href={paths.auth.frontpage}
//         color="inherit"
//         variant="subtitle2"
//         sx={{
//           alignItems: 'center',
//           display: 'inline-flex',
//         }}
//       >
//         <Iconify icon="eva:arrow-ios-back-fill" width={16} />
//         Return to sign in
//       </Link>
//     </Stack>
//   );

//   const renderHead = (
//     <>
//       <PasswordIcon sx={{ height: 96 }} />

//       <Stack spacing={1} sx={{ my: 5 }}>
//         <Typography variant="h3">Forgot your password?</Typography>

//         <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//           Please enter the email address associated with your account and We will email you a link
//           to reset your password.
//         </Typography>
//       </Stack>
//     </>
//   );

//   return (
//     <FormProvider methods={methods} onSubmit={onSubmit}>
//       {renderHead}

//       {renderForm}
//     </FormProvider>
//   );
// }
import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// auth
import { useAuthContext } from 'src/auth/hooks';
// assets
import { PasswordIcon } from 'src/assets/icons';
// components
import Iconify from 'src/components/iconify';
import { FRONTEND_SERVER_BASE_URL } from 'src/config-globals';

import FormProvider, { RHFTextField } from 'src/components/hook-form';
import regex from '../../../regex';

// ----------------------------------------------------------------------

export default function JwtForgotPasswordView() {
  const { forget_password } = useAuthContext();

  // State to track submission success
  const [isSubmitted, setIsSubmitted] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(regex.EMAIL, 'Please enter valid email address'),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  // const onSubmit = handleSubmit(async (data) => {
  //   const payload = {
  //     email: data.email,
  //     app_url: `${FRONTEND_SERVER_BASE_URL}`,
  //   };

  //   try {
  //     await forget_password(payload);
  //     // Set submission state to true
  //     setIsSubmitted(true);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // });
  const onSubmit = handleSubmit(async (data) => {
    const payload = {
      email: data.email,
      app_url: `${FRONTEND_SERVER_BASE_URL}`,
    };

    try {
      await forget_password(payload);
    } catch (error) {
      console.error(error);
    } finally {
      // Set submission state to true, whether success or failure
      setIsSubmitted(true);
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <RHFTextField name="email" label="Email address" />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Send Request
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.auth.frontpage}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderSuccessMessage = (
    <Stack spacing={3}>
      <Typography variant="body1" align="center">
        If the email exists in our system, we will email you a link to reset your password. It
        should appear in your inbox within the next five minutes. If you still don’t see it, please
        check your spam folder before getting in touch!
      </Typography>
      <Link
        component={RouterLink}
        href={paths.auth.frontpage}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: 'center',
          display: 'inline-flex',
          alignSelf: 'center',
        }}
      >
        <Iconify icon="eva:arrow-ios-back-fill" width={16} />
        Return to sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Forgot your password?</Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Please enter the email address associated with your account and we will email you a link
          to reset your password.
        </Typography>
      </Stack>
    </>
  );

  const renderHeadAfterSubmit = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5 }}>
        <Typography variant="h3">Forgot your password?</Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      {!isSubmitted ? renderHead : renderHeadAfterSubmit}

      {/* Conditionally render the form or the success message */}
      {!isSubmitted ? renderForm : renderSuccessMessage}
    </FormProvider>
  );
}
