// @mui
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
// theme
import { bgBlur } from 'src/theme/css';
// routes
// hooks
import { useOffSetTop } from 'src/hooks/use-off-set-top';
// components
import Logo from 'src/components/logo';
//
import { ContentContext } from 'src/store/contentContext';
import { Box, Button, Grid, Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useAuthContext } from 'src/auth/hooks';
import { LoginDialog } from 'src/components/custom-dialog';
import { useBoolean } from 'src/hooks/use-boolean';
import { HEADER } from '../config-layout';
import HeaderShadow from './header-shadow';
import AppWelcome from '../../sections/overview/app/app-welcome';

// ----------------------------------------------------------------------

export default function HeaderSimple({ frontPage, hideIcon }) {
  const theme = useTheme();
  const dialog = useBoolean();
  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);
  const location = useLocation();
  const isVerifiedPage = location.pathname.includes('verifiedbyintegra');
  const [openRegister, setOpenRegister] = useState(false);
  const { is_mainnet_network, user } = useAuthContext();
  const [isMainnet, setIsMainnet] = useState();
  const [file, setFile] = useState(null);

  const { frontPageFile } = useContext(ContentContext);
  console.log('🚀 ~ HeaderSimple ~ frontPageFile:', frontPageFile);

  useEffect(() => {
    setIsMainnet(is_mainnet_network);
  }, [is_mainnet_network]);

  const handleRegisterButtonClick = (value) => {
    setOpenRegister(value);
  };

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo />
        {/* {!isMainnet && (
          <Box component={Paper} px={1} py={0.5} variant="outlined" bgcolor="#FFE286">
            <Typography variant="caption" fontSize="0.85rem">
              Connected to Integra <strong>Testnet</strong>
            </Typography>
          </Box>
        )} */}

        <Stack direction="row" alignItems="center" spacing={1}>
          {/* hid the settings button from the simple header */}
          {/* <SettingsButton /> */}

          {/* {isVerifiedPage && ( */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              dialog.onTrue();
              setOpenRegister(true);
            }}
            // component={RouterLink}
            // color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            Create Account
          </Button>
          {/* )} */}

          {!hideIcon && (
            <Button
              variant="contained"
              color="primary"
              onClick={dialog.onTrue}
              // component={RouterLink}
              // color="inherit"
              sx={{ typography: 'subtitle2', px: '40px' }}
            >
              Login
            </Button>
          )}

          {/* {frontPage ? (
            <Link
              onClick={dialog.onTrue}
              component={RouterLink}
              color="inherit"
              sx={{ typography: 'subtitle1' }}
            >
              Login
            </Link>
          ) : (
            <Link
              href={paths.faqs}
              component={RouterLink}
              color="inherit"
              sx={{ typography: 'subtitle1' }}
            >
              Need help?
            </Link>
          )} */}

          {/* <Link
            href={frontPage ? paths.auth.jwt.login : paths.faqs}
            component={RouterLink}
            color="inherit"
            sx={{ typography: 'subtitle2' }}
          >
            {frontPage ? 'Login' : 'Need help?'}
          </Link> */}
        </Stack>
      </Toolbar>

      <LoginDialog
        open={dialog.value}
        onClose={() => {
          dialog.onFalse();
          setOpenRegister(false);
        }}
        file={frontPageFile}
        openRegister={openRegister}
        handleRegisterButtonClick={handleRegisterButtonClick}
      />

      {offsetTop && <HeaderShadow />}
    </AppBar>
  );
}

HeaderSimple.propTypes = {
  frontPage: PropTypes.bool,
  hideIcon: PropTypes.bool,
};
