import { HmacSHA256 } from 'crypto-js';

// Function to generate an RSA key pair and export it in base64-encoded SPKI (public key) and PKCS#8 (private key) formats.
export async function generateRSAKeyPairBase64() {
  const keyPair = await window.crypto.subtle.generateKey(
    {
      name: 'RSASSA-PKCS1-v1_5',
      modulusLength: 2048,
      publicExponent: new Uint8Array([1, 0, 1]),
      hash: 'SHA-256',
    },
    true,
    ['sign', 'verify']
  );
  const publicKey = await window.crypto.subtle.exportKey('spki', keyPair.publicKey);
  const privateKey = await window.crypto.subtle.exportKey('pkcs8', keyPair.privateKey);
  // Convert the public key to base64
  const inner = String.fromCharCode.apply(null, new Uint8Array(publicKey));
  const publicKeyBase64 = btoa(inner);

  // Format the public key in PEM format
  const publicKeyPEM = `-----BEGIN RSA PUBLIC KEY-----\n${publicKeyBase64}\n-----END RSA PUBLIC KEY-----\n`;
  const finalPublicKey = btoa(publicKeyPEM);
  return {
    publicKey: finalPublicKey, // Use the base64 string directly
    privateKey: btoa(String.fromCharCode.apply(null, new Uint8Array(privateKey))),
  };
}

// Function to sign data with a given base64-encoded PKCS#8 private key and return the signature in base64 format.
export async function signMessageWithBase64Key(base64PrivateKey, message) {
  const privateKeyBuffer = Uint8Array.from(atob(base64PrivateKey), (c) => c.charCodeAt(0));
  const privateKey = await window.crypto.subtle.importKey(
    'pkcs8',
    privateKeyBuffer,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: 'SHA-256',
    },
    true,
    ['sign']
  );
  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const signatureBuffer = await window.crypto.subtle.sign('RSASSA-PKCS1-v1_5', privateKey, data);
  return btoa(String.fromCharCode.apply(null, new Uint8Array(signatureBuffer)));
}

// Function to verify a signature with a given base64-encoded SPKI public key.
export async function verifySignedMessageWithBase64Key(base64PublicKey, signatureBase64, message) {
  const publicKeyBuffer = Uint8Array.from(atob(base64PublicKey), (c) => c.charCodeAt(0));
  const signatureBuffer = Uint8Array.from(atob(signatureBase64), (c) => c.charCodeAt(0));
  const publicKey = await window.crypto.subtle.importKey(
    'spki',
    publicKeyBuffer,
    {
      name: 'RSASSA-PKCS1-v1_5',
      hash: 'SHA-256',
    },
    true,
    ['verify']
  );

  const encoder = new TextEncoder();
  const data = encoder.encode(message);
  const isValid = await window.crypto.subtle.verify(
    'RSASSA-PKCS1-v1_5',
    publicKey,
    signatureBuffer,
    data
  );
  return isValid;
}

export async function generateHMACHash(email) {
  const secretKey = 'EuKL-SgXbeEMjjLBq8ZFXQZzMUqy6iwcA99lLP_B'; // an Identity Verification secret key (web)
  const userIdentifier = email; // the email for your user

  // const hash = crypto.createHmac('sha256', secretKey).update(userIdentifier).digest('hex');
  const hash = await HmacSHA256(userIdentifier, secretKey).toString();
  return hash;
}

export function generateSecureRandomHex(length) {
  const array = new Uint8Array(length / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(16).padStart(2, '0')).join('');
}
